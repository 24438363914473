html, body, #root {
  font-family: serif;
}
#root {
  /* todo: fix background url and size */
  background-image: url('https://jrwilding.com/assets/images/background.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.header {
  font-family: 'Cinzel';
  font-size: calc(10px + 2vmin);
  color: #091339;
  text-shadow: 0px 0px 10px #FFFFFF;
}

.social-links a.social-link svg {
  color: #091339;
  /* todo: fix these svg shadows */
  /*filter: drop-shadow(5px 5px 10px #FFFFFF);
  -webkit-filter: drop-shadow(5px 5px 10px #FFFFFF);*/
}

h1.name {
  font-family: 'Cinzel Decorative';
  font-weight: bold;
  font-size: 3em;
}

h1.name a {
  color: #091339; /*#A52E36;*/
  text-decoration: none;
  text-shadow: 0px 0px 10px #FFFFFF;
}

.subtext {
  font-weight: bold;
}

.page-links {}

.page-links .link {
  color: #181A18;
  text-shadow: 0px 0px 10px #FFFFFF;
  text-decoration: none;
  padding: 0px 25px;
}

.page-links .column-right {
  border-right: 2px solid #091339;
}

.content {
  font-size: 20px;
  text-align: left;
}
